@import url(https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
.CollapsableCard_cardHeader__31N_w {
  padding: 1rem 1.25rem;
  background-color: #424242;
}

.CollapsableCard_cardHeader__31N_w:hover {
  background-color: #424242;
}

.CollapsableCard_cardItem__1jWvg {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #424242;
  background-clip: border-box;
  border-radius: 0.25rem;
  margin: 1.5rem 0;
}

.CollapsableCard_cardBody__TjFpO {
  background-color: #ffffff;
}

.CollapsableCard_shadow__3C-uV {
  box-shadow: 0 0.125em 0.313em rgba(50, 50, 93, 0.09),
    0 0.063em 0.125em rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.3s ease;
}

.CollapsableCard_shadow__3C-uV:hover {
  box-shadow: 0 0.938em 2.188em rgba(50, 50, 93, 0.1),
    0 0.313em 0.938em rgba(0, 0, 0, 0.07);
}

.CollapsableCard_noSelect__2mEij {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.Home_cardOverride__27zQw {
  background-color: #424242 !important;
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

.Home_imgOverride__I6gVd {
  width: 100% !important;
  height: 16rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Home_productHuntGif__eZK5z {
  width: 100%;
  height: 28rem;
}

.Navbar_navLink__xtX-R {
  color: #e6e6e6 !important;
  font-weight: 400 !important;
  font-weight: 600;
  padding: 0.4rem 1.5rem;
  margin: 0 0.5rem;
}

.Navbar_navLink__xtX-R:hover {
  text-decoration: none !important;
  background-color: #f88f2c18;
  border-radius: 1rem;
  color: #f35627 !important;
}

.Navbar_navLinkActive__1eB-k {
  text-decoration: none !important;
  background-color: #f88f2c18;
  border-radius: 1rem;
  color: #f35627 !important;
}

.Navbar_navLinkContainer__5dzWS {
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .Navbar_navDropdown__2gp7N {
    align-self: center;
  }
}

.Navbar_navDropdownToggle__jmhyR {
  color: #182538 !important;
  cursor: pointer;
}

.Navbar_navbarOverride__3K3nL {
  background-color: #2f3031 !important;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

* {
  font-family: 'Muli', 'Arial', 'Sans';
}

html {
  background-color: #2f3031;
}

body > * {
  background-color: #2f3031;
}

p,
ul,
li {
  font-size: 1rem;
  color: #e6e6e6;
}

ul,
li {
  font-size: 1.15rem;
}

ul {
  padding-left: 20px;
}

h1 {
  font-size: 3rem;
}

h1,
h2,
h3,
h4,
h5 {
  color: #e6e6e6;
}

/* Overwrite default bootstrap .btn  */
.btn {
  /* font-size: 1rem !important; */

  /* font-weight: 300; */
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

a:hover {
  text-decoration: none;
  color: rgba(243, 87, 39, 0.692);
}

a {
  color: #f35627;
}

.logo {
  width: 200px;
}

input[type='text'],
input[type='password'],
textarea,
select {
  outline: none;
}

.g-primary-color {
  color: #4192f2 !important;
}

.image-gallery-slide img {
  height: 30rem !important;
  /* object-fit: contain; */
}

.image-gallery-thumbnail-image {
  object-fit: cover;
  width: 10px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #f35627;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 3px solid #f35627;
  }
}

.image-gallery-thumbnail {
  width: 60px !important;
}

.image-gallery-icon:hover,
.image-gallery-icon:focus {
  color: #f35627;
}

.image-gallery-thumbnail.active {
  border: 3px solid #f35627;
}

.image-gallery-thumbnail:hover {
  border: 3px solid #f35627;
}

.image-gallery-thumbnail-image {
  max-height: 5rem !important;
  object-fit: contain;
}

.image-gallery-thumbnail-image {
  max-height: 5rem !important;
  object-fit: contain;
}

.navbar-light,
.navbar-toggler {
  border-color: white !important;
}

/* .navbar-light, */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

