@import '~react-image-gallery/styles/css/image-gallery.css';
@import url('https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap');

* {
  font-family: 'Muli', 'Arial', 'Sans';
}

html {
  background-color: #2f3031;
}

body > * {
  background-color: #2f3031;
}

p,
ul,
li {
  font-size: 1rem;
  color: #e6e6e6;
}

ul,
li {
  font-size: 1.15rem;
}

ul {
  padding-left: 20px;
}

h1 {
  font-size: 3rem;
}

h1,
h2,
h3,
h4,
h5 {
  color: #e6e6e6;
}

/* Overwrite default bootstrap .btn  */
.btn {
  /* font-size: 1rem !important; */

  /* font-weight: 300; */
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

a:hover {
  text-decoration: none;
  color: rgba(243, 87, 39, 0.692);
}

a {
  color: #f35627;
}

.logo {
  width: 200px;
}

input[type='text'],
input[type='password'],
textarea,
select {
  outline: none;
}

.g-primary-color {
  color: #4192f2 !important;
}

.image-gallery-slide img {
  height: 30rem !important;
  /* object-fit: contain; */
}

.image-gallery-thumbnail-image {
  object-fit: cover;
  width: 10px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #f35627;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 3px solid #f35627;
  }
}

.image-gallery-thumbnail {
  width: 60px !important;
}

.image-gallery-icon:hover,
.image-gallery-icon:focus {
  color: #f35627;
}

.image-gallery-thumbnail.active {
  border: 3px solid #f35627;
}

.image-gallery-thumbnail:hover {
  border: 3px solid #f35627;
}

.image-gallery-thumbnail-image {
  max-height: 5rem !important;
  object-fit: contain;
}

.image-gallery-thumbnail-image {
  max-height: 5rem !important;
  object-fit: contain;
}

.navbar-light,
.navbar-toggler {
  border-color: white !important;
}

/* .navbar-light, */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
