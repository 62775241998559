.cardOverride {
  background-color: #424242 !important;
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

.imgOverride {
  width: 100% !important;
  height: 16rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.productHuntGif {
  width: 100%;
  height: 28rem;
}
