.navLink {
  color: #e6e6e6 !important;
  font-weight: 400 !important;
  font-weight: 600;
  padding: 0.4rem 1.5rem;
  margin: 0 0.5rem;
}

.navLink:hover {
  text-decoration: none !important;
  background-color: #f88f2c18;
  border-radius: 1rem;
  color: #f35627 !important;
}

.navLinkActive {
  text-decoration: none !important;
  background-color: #f88f2c18;
  border-radius: 1rem;
  color: #f35627 !important;
}

.navLinkContainer {
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .navDropdown {
    align-self: center;
  }
}

.navDropdownToggle {
  color: #182538 !important;
  cursor: pointer;
}

.navbarOverride {
  background-color: #2f3031 !important;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}
