.cardHeader {
  padding: 1rem 1.25rem;
  background-color: #424242;
}

.cardHeader:hover {
  background-color: #424242;
}

.cardItem {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #424242;
  background-clip: border-box;
  border-radius: 0.25rem;
  margin: 1.5rem 0;
}

.cardBody {
  background-color: #ffffff;
}

.shadow {
  box-shadow: 0 0.125em 0.313em rgba(50, 50, 93, 0.09),
    0 0.063em 0.125em rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.3s ease;
}

.shadow:hover {
  box-shadow: 0 0.938em 2.188em rgba(50, 50, 93, 0.1),
    0 0.313em 0.938em rgba(0, 0, 0, 0.07);
}

.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
